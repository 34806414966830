:root {
    --color-orange: rgb(242, 208, 106);
    --color-gray: rgb(56, 56, 56);
    --color-offwhite: rgb(226, 226, 226);
    --color-blue: rgb(86, 201, 255);
    --color-link: rgb(180, 0, 156);

    --color-primary: rgb(242, 234, 123);

    --color-secondary: rgb(255, 253, 218);

    --color-tertiary: rgb(109, 149, 180);
}
