@import "./variables.css";
/* SHOULDNT NEED the below import line, since I'm just using script tags in my html file! */
/* @import url("https://fonts.googleapis.com/css2?family=Averia+Libre:wght@700&family=Averia+Serif+Libre:ital,wght@1,700&family=Mukta+Vaani:wght@600&family=Prompt:ital,wght@1,700&family=Trykker&display=swap"); */
* {
    box-sizing: border-box;

    -ms-overflow-style: none;
    color: black;
    /* white-space: pre-wrap !important; */
}

.color-primary,
.color-secondary,
.color-tertiary {
    border-radius: 3px;
}

/* NO BORDER RADIUS on the little colored strip underneath the nav bar! */
.color-tertiary.border-bottom {
    border-radius: 0;
}

.color-primary h4,
.color-secondary h4 {
    font-size: 3rem;
}

.color-primary h6,
.color-primary h5,
.color-secondary h6,
.color-secondary h5 {
    font-size: 1.4rem;
}

/* grabbing the link on the ACTIONALERTS PROWL page, but I should also make all the links hovery huh? */
.color-primary > a:hover,
.color-secondary > a:hover,
.color-tertiary > a:hover {
    color: var(--color-link);
}

h1,
h2,
h3,
h4,
.card-title.h5,
div.modal-title.h4 {
    font-family: "Prompt", sans-serif;
}

a,
.ital,
h5,
h6 {
    /* defined my own italic class here! */
    font-family: "Averia Serif Libre", "Lucida Sans", "Lucida Sans Regular",
        "Lucida Grande", "Lucida Sans Unicode", Verdana, sans-serif !important;
}

p,
ul,
li,
input {
    font-family: "Trykker", serif !important;
    /* font-family: 'Mukta Vaani', sans-serif; */
}

strong,
em,
label,
small,
button {
    font-family: "Averia Serif Libre", "Lucida Sans", "Lucida Sans Regular",
        "Lucida Grande", "Lucida Sans Unicode", Verdana, sans-serif !important;
}

img {
    border-radius: 2px;
    border: 4px solid black;
}

img.panther {
    border: 0;
}

h4:after,
h5:after,
h6:after {
    /* THIS IS TO ADD BLANK LINES, preserve line breaks present in the text editor! COOL! */
    content: "\a \a";
    white-space: pre;
}

/* this is for heading text on SubPage BlockContent sections, so the headlines on activism pages etc */
article.subpage-article div.p-md-5 h4,
article.subpage-article div.p-md-5 h5,
article.subpage-article div.p-md-5 h6 {
    font-size: 1.6rem;
}

/* GOOGLE DOC IFRAME! I'm not sure I can actually style this... */
iframe body {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 3rem !important;
}

article p:after {
    /* outline: 5px solid red;  */
    content: "\a";
    white-space: pre;
}

::-webkit-scrollbar {
    display: none;
    width: 0;
}

/* on screens smaller than 500px? make our nav link text smaller */

@media (max-width: 500px) {
    /* here's the heading text on the EVENTS page, which doesnt allow for very long words on mobile. Let's shrink it down so a word like lecture/seminar will fit */
    div.color-secondary.p-5.m-lg-2.col-lg-7.col-10 div.p-lg-4.m-lg-4 h4,
    div.color-secondary.p-5.m-lg-2.col-lg-7.col-10 div.p-lg-4.m-lg-4 h5,
    div.color-secondary.p-5.m-lg-2.col-lg-7.col-10 div.p-lg-4.m-lg-4 h6 {
        font-size: 1.2rem;
    }

    a.nav-link,
    a.dropdown-item {
        font-size: 0.6rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
        word-wrap: break-word;
    }

    h4.display-3 {
        font-size: 2rem;
    }

    .subpage-nav {
        flex-wrap: wrap !important;
        overflow-y: auto !important;
        max-height: 14rem !important;
        max-width: 100% !important;
    }
}

@media (max-width: 900px) {
    .subpage-nav {
        flex-wrap: wrap !important;
        overflow: auto !important;
        max-height: 14rem !important;
        min-width: fit-content !important;
        /* max-width: 50% !important; */
    }
    .nav-background {
        background-color: var(--color-secondary);
        border-bottom: 3px solid black;
        border-radius: 3px;
    }

    /* html body div#root div.App div nav.m-0.p-0.navbar.navbar-expand-lg.navbar-light.bg-light.sticky-top div.container div#basic-navbar-nav.navbar-collapse.collapse.show div.top-bar.color-primary.p-2.navbar-nav */

    #basic-navbar-nav {
        /* justify-content: center; */
        background: var(--color-secondary);

        border: 3px solid black;
        border-radius: 4px;
        margin-bottom: 0.5rem;
    }
    a.join-us {
        margin: 0 2rem !important;
    }
    /* when it's a smaller screen, make the collapsible nav menu a different color, and switch up its margin! */

    /* make collapsible navbar links bigger on mobile */
    .top-bar a.nav-link {
        /* font-size: 1rem; */
    }
}

@media (min-width: 900px) {
    /* this is the annoying CardSubPageSection row, we want a max-height if the screen is big enough... */

    .subpage-section {
        /* max-height: 64rem; */
        height: clamp(32rem, 600rem, 56rem);
    }

    /* this is the card subpage section on the front page... */
    .card-area {
        max-height: 56rem;
    }
    .footer-sub,
    .footer-sub.nav-item,
    .footer-sub a {
        display: inline !important;
        max-width: max-content !important;
    }
    .card {
        min-width: 20rem !important;
    }

    .card-title.h5 {
        font-size: 1.3rem !important;
    }

    .foot-name h5 {
        font-family: "Prompt", sans-serif !important;
        font-size: 1.6rem;
    }

    .foot-nav a {
        font-family: "Trykker", sans-serif !important;
        /* font-family: 'Trykker', serif !important; */
        font-size: 1rem;
        /* font-weight: 500; */
    }
}

/* END MEDIA QUERIES! */

html
    body
    div#root
    div.App
    div
    nav.m-0.navbar.navbar-expand-lg.navbar-light.bg-light.sticky-top
    div.container-fluid
    div.align-items-center.justify-content-center.row
    div.col-lg-8
    div#basic-navbar-nav.navbar-collapse.collapse
    div.navbar-nav
    div.row
    div.col
    a {
    font-size: 1.1rem;
}

html
    body
    div#root
    div.App
    div
    nav.m-0.navbar.navbar-expand-lg.navbar-light.bg-light.sticky-top
    div.container-fluid
    div.align-items-center.justify-content-center.row
    div.col-lg-8
    div#basic-navbar-nav.navbar-collapse.collapse
    div.navbar-nav
    div.row
    div.col
    a.active {
    text-decoration: none !important;
}

html
    body
    div#root
    div.App
    div
    nav.m-0.navbar.navbar-expand-lg.navbar-light.bg-light.sticky-top
    div.container-fluid
    div.align-items-center.justify-content-center.row
    div.col-lg-8
    div#basic-navbar-nav.navbar-collapse.collapse
    div.navbar-nav
    div.row
    div.col
    a:hover {
    color: var(--color-link);
}

.top-bar.navbar-nav a {
    text-decoration: none;
    font-size: 1rem;
}

a.join-us {
    border: 4px solid var(--color-link);
}

a.join-us:hover {
    background-color: var(--color-primary);
    color: black !important;
}

/* these are the links to subpages, displayed in the footer! */
.footer-sub,
.footer-sub.nav-item,
.footer-sub a {
    display: inline !important;
    max-width: max-content !important;
}

.foot-email {
    border: 3px solid black;
    border-radius: 3px;
}
.foot-name h5 {
    font-family: "Prompt", sans-serif !important;
}

.foot-yellow {
    border: 3px solid black;
    border-radius: 3px;
}

.hide-this-image .foot-nav a {
    /* font-family: 'Prompt', sans-serif !important; */
    font-family: "Trykker", serif !important;
    font-weight: bold;
    /* font-size: 0.9rem; */
}

/* MY NAV BAR aka MyNavbar.js */
/* this forces our top navbar links to fill up almost the entire bar! */
.top-bar {
    min-width: 100%;
}

.top-bar a.nav-link {
    min-width: 100%;
    /* TO CENTER THE TEXT INSIDE THE NAVS! */
    display: flex;
    justify-content: center;
}

/*  This centers the text for each navbar item!*/

.top-bar a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.top-bar a:hover {
    color: var(--color-link);
}

.navbar-toggler {
    border: 3px solid black !important;
    background-color: var(--color-primary) !important;
}

/* make the navbar links black instead of gray, by brute force! */
.nav-link,
.dropdown-item {
    color: black !important;
    font-size: 0.9rem;
    /* border: 3px solid black; */
}

.nav-link.isActive {
    color: var(--color-link) !important;
    border: 3px solid var(--color-link);
}

a.nav-link:hover {
    color: var(--color-link) !important;
}

/* this is the SAN FRANCISCO GRAY PANTHERS link in the navbar! */
a h1:hover {
    color: var(--color-link);
}

.color-primary {
    background-color: var(--color-primary);
}

.color-secondary {
    background-color: var(--color-secondary);
}

.color-tertiary {
    background-color: var(--color-tertiary);
}

.head-bar {
    background-color: var(--color-secondary);
}

.foot-name {
    border: none;
}

.foot-email {
    background-color: var(--color-tertiary);
}

figure img {
    /* inline images, within our BlockContent text editor, get rendered as a <figure> <img />  </figure>  */
    max-width: 100%;
}

/* this makes our links a different color, and makes them slightly larger. I'm trying to account for every type of text that could be in a link */
a,
p a,
a strong,
a em {
    color: var(--color-link);

    font-size: 1.2rem;
}

a:hover,
p a:hover,
a strong:hover,
a em:hover {
    color: var(--color-blue);
}

strong {
    font-weight: 900;
    font-size: 1.2rem;
}

/* remove underline from the card section */
.card-link {
    text-decoration: none;
    font-size: 1rem;
}

.card-primary:hover {
    background-color: var(--color-secondary);
}

/* these are the titles on the subPage cards! This is a weird selector, but this is how you get to them! */
/* when i made it an h4, longer words like TRANSPORTATION were being cut of, so i removed that tag but kept this selector rule just in case */
.card-title.h5 h4,
.card-title.h5 {
    color: var(--color-link);
    text-shadow: 1px 1px 1px black;
}

/* this addresses the GET INVOLVED button on the navbar */
a.btn.btn-primary {
    font-size: 1.2rem;
}

a.btn.btn-primary:hover,
button.btn-primary:hover,
button.btn-secondary:hover {
    color: var(--color-link);
}

button.btn-danger:hover,
button.btn-info:hover {
    color: black;
    /* hovering over a red or blue button should give you black button text */
}

/* TABLES! */

tbody tr td {
    cursor: pointer;
}
tbody tr:hover {
    background-color: var(--color-primary) !important;
    color: var(--color-secondary) !important;
}

tbody {
    border: 4px solid black !important;
}

/* TABLE HEADINGS IN OUR MEMBERS TABLE VIEW! COOL! */
.active-column {
    background-color: var(--color-link) !important;
    color: var(--color-secondary);
}

th:hover {
    background-color: var(--color-secondary) !important;
    color: var(--color-link);
}

/* CONTACT PAGE */
.sign-me-up:hover {
    color: var(--color-link) !important;
    background-color: var(--color-primary) !important;
}
