@import '../variables.css';

.container {
  position:fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin-left: 2rem; */
    
    padding: 2rem;
    margin: auto;

    max-width: 20vw;
    /* margin: auto; */
}
/* 
.container h1, .container h2, .container p {
  color: white;
} */

.button:focus {
    border: 3px solid var(--color-link);
}   

/* people */

.person-name {
  /* background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);   */
  background: var(--color-primary);  
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
    box-shadow: 10px 5px 5px  var(--color-link);
}

.people h6 {
    font-size: 2rem;
}
.people li {
    margin: 3rem;
    max-width: max-content;
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 10px 5px 5px rgb(35, 35, 35);
    /* border: 2px solid #0cbcb0; */
    /* border-left: 4px solid #1f9751; */
    /* box-shadow: 3px 3px 5px rgba(50,50,50,0.1); */
    /* position: relative; */
    /* min-width: min-content; */
}

.people li:hover {
    background-color: var(--color-link);
}
.people .name {
    color: black;
    font-size: 1.2rem;
}

.delete {
    background: rgb(255, 102, 102);
    /* position: absolute; */
    /* top: 0;
  left: 0; */
    color: rgb(0, 0, 0);
    border: 2px solid rgb(0, 0, 0);
    padding: 12px 8px;
    text-align: center;
    line-height: 0;
    font-size: 0.9em;
    cursor: pointer;
}

.submit {
    /* position: absolute;
  bottom: 0;
  left: 0; */
    padding: 0.4rem;
    margin: 2rem;
}

/* this will target the X at the top right, that closes the modal */
button:hover {
    background-color: var(--color-link);
    color: white;
    /* border: 3px solid black; */
}

/* p:hover {
  color: aqua;
} */

.update-record form {
    background-color: var(--color-primary);
    padding: 1rem;
}
.update-record label {
    padding: 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.person-field {
    background: rgb(227, 227, 227);
    color: black;
    margin: 0.2rem;
    display: inline;
    line-height: 2rem;
    font-size: 1.2rem;
    padding: 0.2rem;
}

.person-record {
    margin: 1rem;
    padding: 1rem;
    background-color: var(--color-secondary);
    font-weight: bold;
}

.submit {
}

.are-you-sure {
    background-color: rgb(174, 170, 173);
  
    padding: 1rem;
    font-weight: bold;
}

/* .person-field {

}

.hidden {
  display:none;
} */

/* .content {
  padding-right: 30px;
}
.sidebar {
  padding-left: 30px;
}
.sidebar form {
  padding: 20px;
  background: #1f9751;
  border-radius: 10px;
}
.sidebar input, .sidebar select {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  box-sizing: border-box;
  border: 0;
  border-radius: 4px;
  color: #555;
  font-size: 1em;
}
.sidebar label {
  margin: 0 auto 20px;
  display: block;
  color: #fff;
}
.sidebar button {
  color: #fff;
  border: 2px solid #fff;
  padding: 6px 12px;
  background-color: transparent;
  font-size: 1em;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  width: 100%;
}
.sidebar h3 {
  color: #1f9751;
  margin-bottom: 20px;
}
.sidebar aside {
  margin-top: 40px;
  color: #555;
}
.sidebar aside li {
  margin: 10px;
} */
